const Line = () => (
  <svg
    width="3"
    height="44"
    viewBox="0 0 3 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.160156" y="12" width="2" height="20" fill="white" />
  </svg>
);
export default Line;
