const VietnamMiniFlag = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_553_1882)">
      <mask
        id="mask0_553_1882"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="20"
      >
        <path
          d="M19.6602 10C19.6602 15.2467 15.4069 19.5 10.1602 19.5C4.91345 19.5 0.660156 15.2467 0.660156 10C0.660156 4.75329 4.91345 0.5 10.1602 0.5C15.4069 0.5 19.6602 4.75329 19.6602 10Z"
          fill="white"
          stroke="#F2F2F2"
        />
      </mask>
      <g mask="url(#mask0_553_1882)">
        <path d="M0.160156 0H20.1602V20H0.160156V0Z" fill="#D80027" />
        <path
          d="M10.1602 5.21875L11.2383 8.53906H14.7305L11.9063 10.5859L12.9844 13.9062L10.1602 11.8555L7.33594 13.9102L8.41406 10.5898L5.58984 8.53516H9.08203L10.1602 5.21875Z"
          fill="#FFDA44"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_553_1882">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.160156)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default VietnamMiniFlag;
