import React from "react";

const BlueCard = ({
  children,
  className = "",
  padding = "8",
  height = "[200px]",
  width = "[400px]",
  gap = "3",
}) => {
  return (
    <div
      className={`${className} p-${padding} h-${height} lg:w-${width} gap-${gap} rounded-[20px] bg-[#12267066] backdrop-blur-[12px] items-center border border-primary-500-main `}
    >
      {children}
    </div>
  );
};

export default BlueCard;
