const KoreaFlag1 = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_322_1305)">
      <mask
        id="mask0_322_1305"
        // style="mask-type:luminance"
        // maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="96"
        height="96"
      >
        <path
          d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_322_1305)">
        <path d="M0 0H96V96H0V0Z" fill="#EEEEEE" />
        <path
          d="M65.625 62.8125L70.125 58.3125L73.125 61.3125L68.625 65.625L65.625 62.8125ZM58.3125 70.125L62.8125 65.625L65.625 68.625L61.3125 73.125L58.3125 70.125ZM74.625 71.625L78.9375 67.125L81.9375 70.125L77.4375 74.625L74.625 71.625ZM67.125 78.9375L71.625 74.625L74.625 77.4375L70.125 81.9375L67.125 78.9375ZM70.125 67.125L74.625 62.8125L77.4375 65.625L73.125 70.125L70.125 67.125ZM62.8125 74.625L67.125 70.125L70.125 73.125L65.625 77.4375L62.8125 74.625ZM74.625 33.1875L62.8125 21.375L65.625 18.5625L77.625 30.375L74.625 33.1875ZM62.8125 30.375L58.3125 25.875L61.3125 22.875L65.625 27.375L62.8125 30.375ZM70.125 37.6875L65.625 33.1875L68.625 30.375L73.125 34.6875L70.125 37.6875ZM71.625 21.375L67.125 17.0625L70.125 14.0625L74.625 18.5625L71.625 21.375ZM78.9375 28.875L74.625 24.375L77.4375 21.375L81.9375 25.875L78.9375 28.875ZM17.0625 67.125L28.875 78.9375L25.875 81.9375L14.0625 70.125L17.0625 67.125ZM28.875 70.125L33.1875 74.625L30.375 77.4375L25.875 73.125L28.875 70.125ZM21.375 62.8125L25.875 67.125L22.875 70.125L18.5625 65.625L21.375 62.8125ZM25.875 58.3125L37.6875 70.125L34.6875 73.125L22.875 61.3125L25.875 58.3125ZM28.875 17.0625L17.0625 28.875L14.0625 25.875L25.875 14.0625L28.875 17.0625ZM33.1875 21.375L21.375 33.1875L18.5625 30.1875L30.375 18.375L33.1875 21.375ZM37.6875 25.875L25.875 37.6875L22.875 34.6875L34.6875 22.875L37.6875 25.875Z"
          fill="#333333"
        />
        <path
          d="M59.8125 59.8125L36.1875 36.1875C37.7387 34.6363 39.5803 33.4058 41.6071 32.5662C43.6339 31.7267 45.8062 31.2946 48 31.2946C50.1938 31.2946 52.3661 31.7267 54.3929 32.5662C56.4197 33.4058 58.2613 34.6363 59.8125 36.1875C61.3637 37.7388 62.5943 39.5803 63.4338 41.6071C64.2733 43.6339 64.7054 45.8062 64.7054 48C64.7054 50.1938 64.2733 52.3661 63.4338 54.3929C62.5943 56.4197 61.3637 58.2613 59.8125 59.8125Z"
          fill="#D80027"
        />
        <path
          d="M59.8123 59.8125C58.2611 61.3637 56.4195 62.5943 54.3927 63.4338C52.3659 64.2733 50.1936 64.7054 47.9998 64.7054C45.806 64.7054 43.6337 64.2733 41.607 63.4338C39.5802 62.5943 37.7386 61.3637 36.1873 59.8125C34.6361 58.2613 33.4056 56.4197 32.5661 54.3929C31.7265 52.3661 31.2944 50.1938 31.2944 48C31.2944 45.8062 31.7265 43.6339 32.5661 41.6071C33.4056 39.5803 34.6361 37.7387 36.1873 36.1875L59.8123 59.8125Z"
          fill="#0052B4"
        />
        <path
          d="M42.0938 50.4375C46.7019 50.4375 50.4375 46.7019 50.4375 42.0938C50.4375 37.4856 46.7019 33.75 42.0938 33.75C37.4856 33.75 33.75 37.4856 33.75 42.0938C33.75 46.7019 37.4856 50.4375 42.0938 50.4375Z"
          fill="#D80027"
        />
        <path
          d="M53.9062 62.25C58.5144 62.25 62.25 58.5144 62.25 53.9062C62.25 49.2981 58.5144 45.5625 53.9062 45.5625C49.2981 45.5625 45.5625 49.2981 45.5625 53.9062C45.5625 58.5144 49.2981 62.25 53.9062 62.25Z"
          fill="#0052B4"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_322_1305">
        <rect width="96" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default KoreaFlag1;
