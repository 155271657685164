const MiddoLogo = ({ className = "!h-24 !w-24 " }) => (
  <div className="relative">
    <div className={`${className}`}>
      <div className="absolute h-24 !w-24 -top-[14px] -left-4">
        <svg
          width="133"
          height="132"
          viewBox="0 0 133 132"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_42_2484)">
            <rect
              x="16.6665"
              y="14"
              width="95.8289"
              height="96"
              rx="20"
              fill="white"
            />
            <mask
              id="mask0_42_2484"
              // style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="28"
              y="23"
              width="73"
              height="78"
            >
              <path
                d="M100.439 23.2812H28.3804V100.736H100.439V23.2812Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_42_2484)">
              <path
                d="M89.7505 53.733V81.4513C89.7505 86.0219 87.225 89.7474 83.6982 91.6657C84.1667 90.1249 84.406 88.5032 84.406 86.8129V57.1499C84.406 51.8429 81.3901 47.0021 76.6349 44.6765L47.3934 30.3834C46.8848 30.1292 46.3639 29.9013 45.8311 29.7137C48.825 28.3341 52.4042 28.1989 55.7459 29.8347L83.244 43.2927C87.225 45.237 89.7505 49.2975 89.7505 53.7351V53.733Z"
                fill="#8BB7F4"
              />
              <path
                d="M100.439 48.3714V76.0901C100.439 80.6607 97.9137 84.3862 94.3872 86.3045C94.8557 84.7633 95.0949 83.1416 95.0949 81.4513V51.7883C95.0949 46.4816 92.0787 41.6405 87.3235 39.3148L58.0824 25.0219C57.5734 24.7677 57.0529 24.5398 56.52 24.3522C59.5136 22.9726 63.0929 22.8374 66.4345 24.4733L93.9327 37.9311C97.9137 39.8756 100.439 43.9359 100.439 48.3735V48.3714Z"
                fill="#8BB7F4"
              />
              <path
                d="M72.5532 48.6519L45.053 35.1981C37.354 31.4242 28.3804 37.0538 28.3804 45.6464V72.6995C28.3804 74.0809 28.5533 75.4325 28.875 76.7357C29.7135 80.2211 31.6678 83.3717 34.4727 85.7034L50.2103 100.331C51.1916 101.239 52.7799 100.523 52.7519 99.1856L52.6755 94.9798C52.6533 93.8442 53.8358 93.0879 54.8529 93.5881L62.3871 97.2711C70.0818 101.039 79.0596 95.4238 79.0596 86.8146V59.0984C79.0596 54.6587 76.5342 50.6003 72.5511 48.6539L72.5532 48.6519ZM67.7758 80.1505C67.7758 83.073 64.7253 84.9834 62.1054 83.7025L62.0654 83.6802C60.7101 83.0185 59.8535 81.6389 59.8535 80.1282V65.2685C59.8535 64.4939 59.7813 63.9169 59.6526 63.5319C59.5177 63.1465 59.2562 62.8561 58.8541 62.6645C58.0601 62.2753 57.6641 62.745 57.6641 64.0743V75.2024C57.6641 78.1253 54.6137 80.0353 51.9938 78.7547C51.3161 78.4239 50.7634 77.9134 50.3753 77.2962C49.9913 76.6832 49.7781 75.9591 49.7781 75.2024V60.3368C49.7781 59.5945 49.7117 59.0216 49.5809 58.6222C49.4464 58.2248 49.1668 57.9203 48.7424 57.7146C47.9703 57.3395 47.5863 57.8538 47.5863 59.2678V70.2707C47.5863 73.1936 44.5362 75.1078 41.9163 73.8289L41.8801 73.8066C40.5249 73.1453 39.6623 71.7656 39.6623 70.2546V53.515C39.6623 51.1431 40.3841 49.5598 41.8338 48.7671C43.2795 47.9703 45.1434 48.1277 47.4275 49.245C49.1928 50.1083 50.6929 51.367 51.9274 53.0127C52.8545 54.2514 54.3724 55.0541 55.8663 54.6769C56.0675 54.6224 56.2725 54.5922 56.4817 54.5741C57.5293 54.4853 58.824 54.8202 60.3624 55.5725C62.753 56.7443 64.5867 58.3925 65.8575 60.5223C67.1324 62.6483 67.7717 65.1354 67.7717 67.9856V80.1526L67.7758 80.1505Z"
                fill="#3D88ED"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_42_2484"
              x="0.666504"
              y="0"
              width="131.829"
              height="132"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="2"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow_42_2484"
              />
              <feOffset dx="2" dy="4" />
              <feGaussianBlur stdDeviation="8" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0862745 0 0 0 0 0.0862745 0 0 0 0 0.0862745 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_42_2484"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_42_2484"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  </div>
);
export default MiddoLogo;
