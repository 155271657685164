import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ChevronDown,
  FileCode2,
  Headset,
  Home,
  Menu as MenuIcon,
  Rss,
  UserRound,
  X,
} from "lucide-react";
import KoreaMiniFlag from "../assets/korea-mini-flag.jsx";
import Line from "../assets/line.jsx";
import VietnamMiniFlag from "../assets/vietnam-mini-flag.jsx";
import WhiteLogo from "../assets/white-logo.jsx";
import Footer from "./Footer.js";


const ScrollContext = createContext();
export const useScrollContext = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  // Add the new refs for specific sections
  const nufiRef = useRef(null);
  const middoRef = useRef(null);
  const capRef = useRef(null);

  // Function to scroll to the target section
  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 5); // Your existing scroll tracking condition
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ScrollContext.Provider value={{ isScrolled, nufiRef, middoRef, capRef, scrollToSection }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  return useContext(ScrollContext);
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    document.body.classList.toggle("overflow-hidden", !isOpen);
  };
  const { isScrolled } = useScroll();

  const pathname = useLocation();
  const list = [
    {
      icon: <Home />,
      to: "/",
      title: "Home",
    },
    {
      icon: <FileCode2 />,
      to: "/product",
      title: "Products",
    },
    {
      icon: <UserRound />,
      to: "/about",
      title: "About",
    },
    {
      icon: <Headset />,
      to: "/contact",
      title: "Contact",
    },
    {
      icon: <Rss />,
      to: "https://blog.dudaji.com/",
      target: "_blank",
      title: "Blog",
    },
  ];

  return (
    <header
      className={`h-header fixed top-0 left-0 w-full z-20  flex text-white py-5 md:px-[5vw] p-5 items-center transition-colors duration-500 ${
        isScrolled ? "bg-primary-700" : "bg-transparent"
      }`}
    >
      <div className=" flex flex-row w-full justify-between  items-center">
        {/* Logo and Language */}
        <div className="flex items-center gap-3">
          <Link to="/" onClick={() => setIsOpen(false)}>
            <WhiteLogo />
          </Link>
          <div className="hidden md:block lg:block">
            <Line />
          </div>

          <div className=" hidden md:block  lg:block">
            <Menu as="div" className="relative">
              <MenuButton className="cursor-pointer">
                <a className="w-full bg-white hover:bg-gray-200 rounded-xl cursor-pointer flex flex-row items-center justify-center p-3 text-[#4C4C4C] gap-2  text-16-600-20 ">
                  <KoreaMiniFlag />
                  Korean
                  <ChevronDown />
                </a>{" "}
              </MenuButton>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-150"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="absolute mt-2 bg-white border rounded-xl shadow-lg  z-10">
                  <MenuItem>
                    <a className="w-full hover:bg-gray-200 rounded-t-xl cursor-pointer flex flex-row  justify-center p-3 text-[#4C4C4C] gap-2  text-16-600-20 ">
                      <KoreaMiniFlag />
                      Korean
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      className=" hover:bg-gray-200 cursor-pointer rounded-b-xl flex flex-row items-center align-middle p-3 text-[#4C4C4C] gap-2 text-16-600-20 "
                      href="https://dudaji.vn"
                      target="_blank"
                    >
                      <VietnamMiniFlag />
                      Vietnamese
                    </a>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        </div>

        {/* Header Navigation Links*/}
        <ul className="lg:flex md:flex gap-3 hidden navigation">
          {list.map((item) => (
            <li>
              <Link
                to={item.to}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                target={item.target || "_self"}
                className={`page-section p-3 font-semibold rounded-xl md:hover:bg-error-200 ${
                  pathname.pathname === item.to &&
                  "bg-error-300 md:hover:bg-error-300 "
                }`}
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>

        <button
          className="md:hidden lg:hidden gap-3 size-12 justify-center flex items-center align-center rounded-xl transition-all active:bg-error-300 "
          onClick={toggleNavbar}
        >
          {isOpen ? <X /> : <MenuIcon />}{" "}
        </button>
      </div>
      <div
        className={`top-0 left-0 w-screen h-screen absolute z-40 bg-white/90 backdrop-blur-sm md:hidden lg:hidden transition-all duration-500 ease-in-out ${
          isOpen ? "" : "hidden"
        }`}
        onClick={toggleNavbar}
      ></div>
      <div
        className={`mobile-nav md:hidden lg:hidden overflow-y-auto absolute z-50 w-[80vw] right-0 top-0 h-dvh text-[#333] flex flex-col justify-between items-stretch bg-white transition-all duration-500 ease-in-out hide shadow-[-4px_0px_20px_0px_rgba(0,0,0,0.08)] ${
          isOpen
            ? "translate-x-0 overflow-y-auto"
            : "translate-x-full overflow-hidden"
        }`}
      >
        <div class="flex flex-col" onClick={() => setIsOpen(false)}>
          <div class="w-full md:px-[5vw] p-5 h-[84px] flex justify-end pr-5">
            <button
              className="lg:hidden gap-3 size-12 justify-center flex items-center align-center active:text-white active:bg-primary-700 rounded-xl transition-all "
              onClick={toggleNavbar}
            >
              {isOpen ? <X /> : <MenuIcon />}
            </button>
          </div>
          {list.map((item) => (
            <Link
              to={item.to}
              hrefLang="top"
              target={item.target || "_self"}
              onClick={() => {
                window.scrollTo(0, 0);
                toggleNavbar();
              }} // Close menu on click
              className={`p-5 w-full font-semibold  md:hover:bg-error-200 ${
                pathname.pathname === item.to &&
                "bg-error-300 md:hover:bg-error-300 "
              }`}
            >
              <div className="flex flex-row gap-5 justify-between">
                <div
                  className={`flex flex-row gap-5 text-neutral-800 ${
                    pathname.pathname === item.to &&
                    "text-white md:hover:bg-error-300 "
                  }`}
                >
                  {item.icon}
                  {item.title}
                </div>
                {/* <div className="flex flex-row gap-1">
                  <div className="mt-1 h-3 min-w-3 rounded-[4px] bg-primary-700 text-wrap" />
                  <div className="mt-1 size-3 rounded-[4px] bg-secondary" />
                </div> */}
              </div>
            </Link>
          ))}
        </div>
        <Footer isNavbar={true} />
      </div>
    </header>
  );
};

export default Header;
