import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header, { ScrollProvider } from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import ProductPage from "./pages/Product";
import ContactPage from "./pages/Contact";
import NotFoundPage from "./pages/NotFoundPage"; // Import the 404 component

function App() {
  return (
    <Router>
      <ScrollProvider>
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
          </Routes>
        </main>
        <Footer />
      </ScrollProvider>
    </Router>
  );
}

export default App;
