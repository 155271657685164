const VietnamFlag = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_322_1316)">
      <mask
        id="mask0_322_1316"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="96"
        height="96"
      >
        <path
          d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_322_1316)">
        <path d="M0 0H96V96H0V0Z" fill="#D80027" />
        <path
          d="M48 25.05L53.175 40.9875H69.9375L56.3813 50.8125L61.5562 66.75L48 56.9063L34.4437 66.7688L39.6188 50.8313L26.0625 40.9688H42.825L48 25.05Z"
          fill="#FFDA44"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_322_1316">
        <rect width="96" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default VietnamFlag;
