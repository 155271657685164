const KoreaMiniFlag = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_553_1868)">
      <mask
        id="mask0_553_1868"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="20"
      >
        <path
          d="M20.1602 10C20.1602 15.2467 15.9069 19.5 10.6602 19.5C5.41345 19.5 1.16016 15.2467 1.16016 10C1.16016 4.75329 5.41345 0.5 10.6602 0.5C15.9069 0.5 20.1602 4.75329 20.1602 10Z"
          fill="white"
          stroke="#F2F2F2"
        />
      </mask>
      <g mask="url(#mask0_553_1868)">
        <path d="M0.660156 0H20.6602V20H0.660156V0Z" fill="#F2F2F2" />
        <path
          d="M14.332 13.0859L15.2695 12.1484L15.8945 12.7734L14.957 13.6719L14.332 13.0859ZM12.8086 14.6094L13.7461 13.6719L14.332 14.2969L13.4336 15.2344L12.8086 14.6094ZM16.207 14.9219L17.1055 13.9844L17.7305 14.6094L16.793 15.5469L16.207 14.9219ZM14.6445 16.4453L15.582 15.5469L16.207 16.1328L15.2695 17.0703L14.6445 16.4453ZM15.2695 13.9844L16.207 13.0859L16.793 13.6719L15.8945 14.6094L15.2695 13.9844ZM13.7461 15.5469L14.6445 14.6094L15.2695 15.2344L14.332 16.1328L13.7461 15.5469ZM16.207 6.91406L13.7461 4.45312L14.332 3.86719L16.832 6.32812L16.207 6.91406ZM13.7461 6.32812L12.8086 5.39062L13.4336 4.76562L14.332 5.70312L13.7461 6.32812ZM15.2695 7.85156L14.332 6.91406L14.957 6.32812L15.8945 7.22656L15.2695 7.85156ZM15.582 4.45312L14.6445 3.55469L15.2695 2.92969L16.207 3.86719L15.582 4.45312ZM17.1055 6.01562L16.207 5.07812L16.793 4.45312L17.7305 5.39062L17.1055 6.01562ZM4.21484 13.9844L6.67578 16.4453L6.05078 17.0703L3.58984 14.6094L4.21484 13.9844ZM6.67578 14.6094L7.57422 15.5469L6.98828 16.1328L6.05078 15.2344L6.67578 14.6094ZM5.11328 13.0859L6.05078 13.9844L5.42578 14.6094L4.52734 13.6719L5.11328 13.0859ZM6.05078 12.1484L8.51172 14.6094L7.88672 15.2344L5.42578 12.7734L6.05078 12.1484ZM6.67578 3.55469L4.21484 6.01562L3.58984 5.39062L6.05078 2.92969L6.67578 3.55469ZM7.57422 4.45312L5.11328 6.91406L4.52734 6.28906L6.98828 3.82812L7.57422 4.45312ZM8.51172 5.39062L6.05078 7.85156L5.42578 7.22656L7.88672 4.76562L8.51172 5.39062Z"
          fill="#333333"
        />
        <path
          d="M13.1211 12.4609L8.19922 7.53906C8.52239 7.21589 8.90606 6.95953 9.32831 6.78463C9.75055 6.60973 10.2031 6.51971 10.6602 6.51971C11.1172 6.51971 11.5698 6.60973 11.992 6.78463C12.4143 6.95953 12.7979 7.21589 13.1211 7.53906C13.4443 7.86224 13.7006 8.2459 13.8755 8.66815C14.0504 9.0904 14.1404 9.54296 14.1404 10C14.1404 10.457 14.0504 10.9096 13.8755 11.3319C13.7006 11.7541 13.4443 12.1378 13.1211 12.4609Z"
          fill="#D80027"
        />
        <path
          d="M13.1209 12.4609C12.7977 12.7841 12.4141 13.0405 11.9918 13.2154C11.5696 13.3903 11.117 13.4803 10.66 13.4803C10.2029 13.4803 9.75038 13.3903 9.32813 13.2154C8.90588 13.0405 8.52222 12.7841 8.19904 12.4609C7.87587 12.1378 7.61951 11.7541 7.44461 11.3319C7.26971 10.9096 7.17969 10.457 7.17969 10C7.17969 9.54296 7.26971 9.0904 7.44461 8.66815C7.61951 8.2459 7.87587 7.86224 8.19904 7.53906L13.1209 12.4609Z"
          fill="#0052B4"
        />
        <path
          d="M9.42969 10.5078C10.3897 10.5078 11.168 9.72956 11.168 8.76953C11.168 7.8095 10.3897 7.03125 9.42969 7.03125C8.46966 7.03125 7.69141 7.8095 7.69141 8.76953C7.69141 9.72956 8.46966 10.5078 9.42969 10.5078Z"
          fill="#D80027"
        />
        <path
          d="M11.8906 12.9688C12.8507 12.9688 13.6289 12.1905 13.6289 11.2305C13.6289 10.2704 12.8507 9.49219 11.8906 9.49219C10.9306 9.49219 10.1523 10.2704 10.1523 11.2305C10.1523 12.1905 10.9306 12.9688 11.8906 12.9688Z"
          fill="#0052B4"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_553_1868">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.660156)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default KoreaMiniFlag;
