import home_product_1 from "./assets/images/home_product_1.jpg";
import home_product_2 from "./assets/images/home_product_2.jpg";

import NufiVideo1 from "./assets/videos/video.mp4";
import MiddoVideo1 from "./assets/videos/video-2.mp4";
import CapVideo1 from "./assets/videos/video-3.mp4";


// LLM images
import llmDudajiGPT from "./assets/images/llm/llm_dudaji_gpt.jpg";
import llmDudajiRAG from "./assets/images/llm/llm_dudaji_rag.jpg";
import llmDudajiSafetyLaw from "./assets/images/llm/llm_dudaji_safety_law.jpg";
import llmDudajiShinhan from "./assets/images/llm/llm_shinhan_chatbot_01.jpg";


// MLOps images
import mlopsCap01 from "./assets/images/mlops/mlops_cap_01.jpg";
import mlopsNufi01 from "./assets/images/mlops/mlops_nufi_01.jpg";
import mlopsNufi02 from "./assets/images/mlops/mlops_nufi_02.jpg";
import mlopsNufiQuantPlanner from "./assets/images/mlops/mlops_nufi_quantplanner.jpg";

// ROS images
import rosLine01 from "./assets/images/ros/ros_line_01.jpg";
import rosMove01 from "./assets/images/ros/ros_move_01.gif";
import rosReal01 from "./assets/images/ros/ros_real_01.jpg";
import rosSimul01 from "./assets/images/ros/ros_simul_01.jpg";
import rosVisionBot01 from "./assets/images/ros/ros_vision_bot_01.jpg";

// Vision images
import vision3dDetect01 from "./assets/images/vision/vision_3d_detect_01.jpg";
import visionAnomaly01 from "./assets/images/vision/vision_anomaly_01.jpg";
import visionBotanic01 from "./assets/images/vision/vision_botanic_01.jpg";
import visionPCB01 from "./assets/images/vision/vision_pcb_01.jpg";
import visionRecycle01 from "./assets/images/vision/vision_recycle_01.jpg";
import visionSafety01 from "./assets/images/vision/vision_saftey_01.jpg";
import visionSOD01 from "./assets/images/vision/vision_sod_01.jpg";
import visionSODCars from "./assets/images/vision/vision_sod_cars.gif";

// Histoy Images
import historyConfVN2 from "./assets/images/history/history_conf_vn2_.jpg";
import historyConfVN from "./assets/images/history/history_conf_vn_.jpg";
import historyConfCDN from "./assets/images/history/history_cdn_forums_attend.jpg";
import historyConfISCA from "./assets/images/history/history_isca_2024.jpg";
import historyConfKubeSeoul from "./assets/images/history/history_kube_forums_seoul.jpg";
import historyConfKubecon from "./assets/images/history/history_kubecon_attend.jpg";
import historyDudajiBooks from "./assets/images/history/history_dudaji_books_.jpg";
import historyDudajiKR2017 from "./assets/images/history/history_dudaji_kr_2017_.jpg";
import historyDudajiOfficePangyo from "./assets/images/history/history_dudaji_office_pangyo_.jpg";
import historyDudajiStart03 from "./assets/images/history/history_dudaji_start_03_.jpg";
import historyDudajiStart04 from "./assets/images/history/history_dudaji_start_04_.jpg";
import historyEmergingAI1002023 from "./assets/images/history/history_emerging_ai_100_2023_.jpg";
import historyShHongKeynote from "./assets/images/history/history_shhong_keynote_.jpg";
import historyVNDudajiOfficeLogo from "./assets/images/history/history_vn_dudaji_office_logo_.jpg";
import historyVNKickoff from "./assets/images/history/history_vn_kickoff_.jpg";
import historyVNMOU from "./assets/images/history/history_vn_mou_.jpg";
import historyKES2024 from "./assets/images/history/history_kes2024_.jpg";
import historyDudajiKRVN from "./assets/images/history/history_dudaji_kr_vn_seminar_2023.jpg";
import historyJunVN from "./assets/images/history/history_vn_jun_2024.jpg";

// NuFi Images
import nufiChippulse from "./assets/images/nufi/nufi_chippulse_.jpg";
import nufiComponents from "./assets/images/nufi/nufi_components_.jpg";
import nufiDeploy from "./assets/images/nufi/nufi_deploy_.jpg";
import nufiLab from "./assets/images/nufi/nufi_lab_.jpg";
import nufiModelZoo1 from "./assets/images/nufi/nufi_modelzoo1_.jpg";
import nufiModelZoo2 from "./assets/images/nufi/nufi_modelzoo2_.jpg";
import nufiModelZoo3 from "./assets/images/nufi/nufi_modelzoo3_.jpg";
import nufiQuantPlanner from "./assets/images/nufi/nufi_quantplanner_.jpg";
import nufiStreamMonitor from "./assets/images/nufi/nufi_stream_monitor_.jpg";
import nufiStreamer from "./assets/images/nufi/nufi_streamer_.jpg";
// Middo Images
import middoLanguage from "./assets/images/middo/middo_languages_.jpg";
import middoMain from "./assets/images/middo/middo_main_.jpg";
import middoPlatform from "./assets/images/middo/middo_mulit_platform_.jpg";
import middoSpaces from "./assets/images/middo/middo_spaces_.jpg";
import middoVideoCall from "./assets/images/middo/middo_video_call_.jpg";
import middoMarketing1 from "./assets/images/middo/middo_two_steps_.jpg";
import middoMarketing2 from "./assets/images/middo/middo_united_global_.jpg";

// Cap Images
import capPipelines from "./assets/images/cap/cap_pipelines_01_.jpg";
import capRegistryHarbor from "./assets/images/cap/cap_registry_harbor_01_.jpg";
import capStorages from "./assets/images/cap/cap_storages_01_.jpg";
import capTensorboard from "./assets/images/cap/cap_tensorboard_.jpg";
import capTuner from "./assets/images/cap/cap_tuner_01_.jpg";

// Vision AI images
export const TabHome_Slide1 = [
  visionSODCars,
  visionSOD01,
  visionPCB01,
  visionBotanic01,
  visionRecycle01,
  vision3dDetect01,
  visionAnomaly01,
];

// LLM images
export const TabHome_Slide2 = [
  llmDudajiSafetyLaw,
  llmDudajiShinhan,
  llmDudajiRAG,
  llmDudajiGPT,
];

// ROS images
export const TabHome_Slide3 = [
  rosMove01,
  rosLine01,
  rosReal01,
  rosSimul01,
  rosVisionBot01,
];

// MLOps images
export const TabHome_Slide4 = [
  mlopsNufi01,
  mlopsCap01,
  mlopsNufi02,
  mlopsNufiQuantPlanner,
];



//AboutUs, History
export const TabAbout_OurStory1 = [
  { src: historyDudajiKR2017},
  { src: historyDudajiOfficePangyo},
  { src: historyDudajiStart03},
  { src: historyDudajiStart04},
  { src: historyConfKubeSeoul},
  { src: historyShHongKeynote},
  { src: historyDudajiBooks},
];
export const TabAbout_OurStory2 = [
  { src: historyVNKickoff },
  { src: historyVNDudajiOfficeLogo },
  { src: historyVNMOU },
  { src: historyDudajiKRVN},
  { src: historyJunVN},
];
export const TabAbout_OurStory3 = [
  { src: historyEmergingAI1002023 },
  { src: historyConfISCA},
  { src: historyConfKubecon},
  { src: historyDudajiKRVN},
  { src: historyConfVN },
  { src: historyConfVN2 },
  { src: historyKES2024 },
];

// NuFi Products
export const TabProduct_Nufi = [
  {
    type: "video",
    isVideo: true,
    sources: [
      {
        src: "https://youtu.be/X5vnZWU8-68", // YouTube 비디오 링크
        thumbnail: "https://img.youtube.com/vi/X5vnZWU8-68/hqdefault.jpg", // YouTube 썸네일 URL
      },
    ],
  },
  {
    type: "image",
    isVideo: false,
    sources: [
      { src: nufiComponents },
      { src: nufiModelZoo1 },
      { src: nufiModelZoo3 },
      { src: nufiLab },
      { src: nufiChippulse},
      { src: nufiQuantPlanner},
      { src: nufiDeploy },
    ],
  },
];

// Middo Products


export const TabProduct_Middo = [
  {
    type: "video",
    isVideo: true,
    sources: [
      {
        src: "https://www.youtube.com/watch?v=wbJReCcbUZ8", // YouTube 비디오 링크
        thumbnail: "https://img.youtube.com/vi/wbJReCcbUZ8/hqdefault.jpg", // YouTube 썸네일 URL
      },
    ],
  },
  {
    type: "image",
    isVideo: false,
    sources: [
      { src: middoMain},
      { src: middoPlatform },
      { src: middoLanguage }, 
      { src: middoVideoCall},
      { src: middoSpaces },
      { src: middoMarketing1 },
      { src: middoMarketing2 },
    ],
  },
];

// CAP Products
export const TabProduct_CAP = [
  {
    type: "image",
    isVideo: false,
    sources: [
      { src: capTuner },
      { src: capPipelines },
      { src: capRegistryHarbor },
      { src: capStorages },
      { src: capTensorboard},
    ],
  },
];

export const TabProduct_NufiDocuments = [
  {
    title: "NuFi Streamer",
    href: "http://nufi.me/",
  },
  {
    title: "NuFi CLI",
    href: "https://pypi.org/project/nufictl/",
  }
];
export const TabProduct_MiddoDocuments = [
  {
    title: "Middo Documents",
    href: "https://docs.middo.app/",
  },

];
export const TabProduct_CAPDocuments = [
  {
    title: "CAP Tutorial for E2E MLOps",
    href: "https://doc.gocap.kr",
  },
];

export const TabAbout_PaperAboutUs = [
  {
    url: "https://www.whitepaper.co.kr/news/articleView.html?idxno=236168",
    title: "[스타트업이 미래다] AI 솔루션 기업 ㈜두다지",
    imgUrl:
      "http://www.whitepaper.co.kr/news/thumbnail/202407/236168_169009_3051_v150.jpg",
  },
  {
    url: "https://www.aitimes.com/news/articleView.html?idxno=156523",
    title: "[신년사] 두다지 “AI 반도체 포함 ML옵스 솔루션으로 매출 100억 목표”",
    imgUrl:
      "https://cdn.aitimes.com/news/thumbnail/202401/156523_167930_2310_v150.jpg",
  },
  {
    url: "https://it.chosun.com/news/articleView.html?idxno=2022110201260",
    title: "두다지·비엠아이, 3D 기반 폐플라스틱 선별 로봇 시스템 개발",
    imgUrl:
      "https://cdn.it.chosun.com/news/thumbnail/202211/2022110201260_1_v150.jpg",
  },
  {
    url: "https://www.mk.co.kr/news/it/11123037",
    title: "서울 AI허브, 스타트업 글로벌 개발자 연계로 인력난 해소 - 매일경제",
    imgUrl:
      "https://wimg.mk.co.kr/news/cms/202409/24/news-p.v1.20240924.c7fe180b81434f6a861bbd936f076936_P1.png",
  },
  {
    url: "http://www.itdaily.kr/news/articleView.html?idxno=212849",
    title: "사피온, ‘MWC 2023’서 국내 최초 언어모델 시연",
    imgUrl:
      "https://cdn.itdaily.kr/news/thumbnail/202302/212849_216014_4223_v150.jpg",
  },
  {
    url: "https://dream.kotra.or.kr/kotranews/cms/news/actionKotraBoardDetail.do?SITE_NO=3&MENU_ID=110&CONTENTS_NO=1&bbsGbn=245&bbsSn=245&pNttSn=197532",
    title: "2022 한국-베트남 디지털 전환 포럼 참관기",
    // imgUrl: "https://dream.kotra.or.kr/type/news/img/layout/logo_navi.jpg",
  },
];
