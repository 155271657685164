// src/pages/NotFoundPage.js
import React from "react";
import Gradient from "../assets/images/gradient.png";

function NotFoundPage() {
    return (
      <div
        className="w-full min-h-screen flex flex-col items-center justify-center bg-neutral-100"
        style={{
          backgroundImage: `url(${Gradient})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-white bg-opacity-80 rounded-lg p-10 shadow-lg max-w-md text-center">
          <h1 className="text-5xl font-bold text-blue-500 mb-4">
            Page Not Found
          </h1>
          <p className="text-lg text-neutral-700 mb-6">
            Sorry, the page you are looking for does not exist.
          </p>
          <a
            href="/"
            className="inline-block px-6 py-3 bg-primary-700 text-white rounded-lg font-semibold shadow hover:bg-primary-800"
          >
            Go Back Home
          </a>
        </div>
      </div>
    );
  }

export default NotFoundPage;
