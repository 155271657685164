const WhiteLogo = () => (
  <svg
    width="110"
    height="40"
    viewBox="0 0 110 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.8749 22.5126H68.564V20.0862H55.6113C54.7301 20.0862 54.2955 19.7241 54.2955 18.9877V15.7888H68.5881V13.3624H51V19.3378C51 21.4382 52.2916 22.4885 54.8749 22.4885V22.5126Z"
      fill="white"
    />
    <path
      d="M58.267 30.5281H61.6108V26.4358H68.5881V23.9612H51V26.4358H58.267V30.5281Z"
      fill="white"
    />
    <path
      d="M84.595 26.798V30.5281H87.8664V21.74H91.0533V19.2533H87.8664V13.3745H84.595V23.4059C84.076 24.6251 82.8809 25.2287 81.0098 25.2287H75.8553C74.9499 25.2287 74.4912 24.7338 74.4912 23.7439V15.8129H81.6013V13.3866H71.2319V24.1423C71.2319 26.4721 72.4632 27.6309 74.9137 27.6309H81.1305C82.6636 27.6309 83.8104 27.3412 84.583 26.7738L84.595 26.798Z"
      fill="white"
    />
    <path
      d="M97.8011 23.5991C98.0667 24.0457 98.3685 24.4441 98.6944 24.7941C100.167 26.4117 102.062 27.5585 104.38 28.2466V25.8081C103.028 25.1804 101.917 24.2751 101.06 23.1041C99.974 21.6314 99.4308 19.3499 99.4308 16.2717V15.825H103.958V13.3987H91.6205V15.825H96.1715V16.2717C96.1715 19.362 95.6283 21.6314 94.5418 23.1041C93.6847 24.2751 92.5742 25.1804 91.2222 25.8081V28.2466C93.5399 27.5585 95.4351 26.4117 96.9078 24.7941C97.2338 24.432 97.5355 24.0336 97.8011 23.5991Z"
      fill="white"
    />
    <path d="M109.16 13.3745H105.865V30.516H109.16V13.3745Z" fill="white" />
    <path
      d="M54.3077 36.3466C54.3077 36.9502 54.1387 37.4209 53.8128 37.7469C53.4869 38.0728 53.0161 38.2418 52.4125 38.2418H51.2295V34.6204H52.4849C53.0644 34.6204 53.511 34.7652 53.8369 35.067C54.1508 35.3688 54.3077 35.7913 54.3077 36.3587V36.3466ZM53.2816 36.3828C53.2816 36.0448 53.2213 35.8034 53.0885 35.6464C52.9557 35.4895 52.7626 35.405 52.4849 35.405H52.1952V37.433H52.4125C52.7143 37.433 52.9316 37.3485 53.0644 37.1795C53.1971 37.0105 53.2696 36.7449 53.2696 36.3828H53.2816Z"
      fill="white"
    />
    <path
      d="M65.9565 34.6083V36.7932C65.9565 37.264 65.8237 37.6382 65.5581 37.8917C65.2926 38.1573 64.9063 38.278 64.3993 38.278C63.8923 38.278 63.5301 38.1573 63.2646 37.9038C62.999 37.6503 62.8662 37.2881 62.8662 36.8173V34.6083H63.8561V36.7328C63.8561 36.9863 63.9044 37.1795 64.0009 37.2881C64.0975 37.4088 64.2424 37.4571 64.4234 37.4571C64.6286 37.4571 64.7735 37.3968 64.858 37.2881C64.9425 37.1674 64.9908 36.9863 64.9908 36.7208V34.5962H65.9686L65.9565 34.6083Z"
      fill="white"
    />
    <path
      d="M77.8108 36.3466C77.8108 36.9502 77.6418 37.4209 77.3158 37.7469C76.9899 38.0728 76.5191 38.2418 75.9156 38.2418H74.7446V34.6204H76.0001C76.5795 34.6204 77.0261 34.7652 77.3521 35.067C77.6659 35.3688 77.8229 35.7913 77.8229 36.3587L77.8108 36.3466ZM76.7968 36.3828C76.7968 36.0448 76.7364 35.8034 76.6036 35.6464C76.4708 35.4895 76.2777 35.405 76.0001 35.405H75.7103V37.433H75.9276C76.2294 37.433 76.4467 37.3485 76.5795 37.1795C76.7123 37.0105 76.7847 36.7449 76.7847 36.3828H76.7968Z"
      fill="white"
    />
    <path
      d="M88.6996 38.2418L88.5185 37.5658H87.3355L87.1544 38.2418H86.0801L87.2631 34.6083H88.5668L89.7619 38.2418H88.6875H88.6996ZM88.3254 36.757L88.1684 36.1655C88.1322 36.0327 88.0839 35.8637 88.0357 35.6464C87.9874 35.4412 87.9512 35.2843 87.927 35.1998C87.9149 35.2843 87.8787 35.4171 87.8425 35.6102C87.7942 35.8034 87.6977 36.1896 87.5528 36.757H88.3254Z"
      fill="white"
    />
    <path
      d="M97.8976 38.3625C97.7166 38.3625 97.5476 38.3504 97.3906 38.3142V37.5537C97.3906 37.5537 97.4993 37.5779 97.5596 37.5899C97.62 37.602 97.6803 37.6141 97.7528 37.6141C97.9097 37.6141 98.0304 37.5658 98.1028 37.4692C98.1753 37.3727 98.2115 37.2037 98.2115 36.9622V34.6204H99.2013V36.8777C99.2013 37.3727 99.0927 37.7348 98.8754 37.9883C98.6581 38.2418 98.3443 38.3625 97.9097 38.3625H97.8976Z"
      fill="white"
    />
    <path d="M107.99 38.2418V34.6204H108.98V38.2418H107.99Z" fill="white" />
    <path
      d="M7.43978 19.5971C3.21463 20.577 0.0641968 24.3385 0.000977545 28.8377C-0.0727783 33.969 4.03648 38.3416 9.15724 38.584C10.5165 38.6472 11.823 38.4259 13.0031 37.9728C13.6985 37.7094 14.4571 37.741 15.1525 38.0045C17.9026 39.0581 20.8844 39.6376 24.0032 39.6376C27.1221 39.6376 30.1039 39.0581 32.8539 38.0045C33.5388 37.741 34.308 37.7094 35.0034 37.9728C36.194 38.4259 37.49 38.6472 38.8492 38.584C43.97 38.3416 48.0793 33.969 48.0055 28.8377C47.9423 24.328 44.7919 20.577 40.5667 19.5866C39.3023 19.2915 38.3751 18.2063 38.3751 16.9103V15.0453C38.3751 7.20612 32.2323 0.589163 24.4036 0.367895C16.575 0.146628 9.62085 6.66875 9.62085 14.7397V16.9103C9.62085 18.2063 8.69363 19.2915 7.42925 19.5866L7.43978 19.5971Z"
      fill="white"
    />
    <path
      d="M29.2929 9.67163C29.6933 9.67163 30.02 9.99826 30.02 10.3987C30.02 10.799 29.6933 11.1257 29.2929 11.1257C28.8926 11.1257 28.5659 10.799 28.5659 10.3987C28.5659 9.99826 28.8926 9.67163 29.2929 9.67163Z"
      fill="#122670"
    />
    <path
      d="M18.7246 9.67163C19.125 9.67163 19.4516 9.99826 19.4516 10.3987C19.4516 10.799 19.125 11.1257 18.7246 11.1257C18.3242 11.1257 17.9976 10.799 17.9976 10.3987C17.9976 9.99826 18.3242 9.67163 18.7246 9.67163Z"
      fill="#122670"
    />
    <path
      d="M24.0032 18.1641C25.8886 18.1641 27.417 16.6357 27.417 14.7503C27.417 12.8649 25.8886 11.3364 24.0032 11.3364C22.1178 11.3364 20.5894 12.8649 20.5894 14.7503C20.5894 16.6357 22.1178 18.1641 24.0032 18.1641Z"
      fill="#FF9792"
    />
    <path
      d="M12.9085 27.1939C12.5819 26.8673 12.034 26.8673 11.7074 27.1939C11.3807 27.5206 11.3807 28.0685 11.7074 28.3951L14.1097 30.7974C14.4363 31.1241 14.9842 31.1241 15.3109 30.7974C15.6375 30.4708 15.6375 29.9229 15.3109 29.5963L12.9085 27.1939Z"
      fill="#122670"
    />
    <path
      d="M10.8324 29.2591C10.5057 28.9325 9.95784 28.9325 9.63121 29.2591C9.30457 29.5858 9.30457 30.1337 9.63121 30.4603L12.0335 32.8626C12.3602 33.1893 12.9081 33.1893 13.2347 32.8626C13.5613 32.536 13.5613 31.9881 13.2347 31.6615L10.8324 29.2591Z"
      fill="#122670"
    />
    <path
      d="M8.76743 31.3348C8.4408 31.0082 7.8929 31.0082 7.56626 31.3348C7.23963 31.6615 7.23963 32.2094 7.56626 32.536L9.9686 34.9383C10.2952 35.265 10.8431 35.265 11.1698 34.9383C11.4964 34.6117 11.4964 34.0638 11.1698 33.7372L8.76743 31.3348Z"
      fill="#122670"
    />
    <path
      d="M36.3099 27.1939C35.9833 26.8673 35.4354 26.8673 35.1087 27.1939L32.7064 29.5963C32.3798 29.9229 32.3798 30.4708 32.7064 30.7974C33.033 31.1241 33.5809 31.1241 33.9076 30.7974L36.3099 28.3951C36.6365 28.0685 36.6365 27.5206 36.3099 27.1939Z"
      fill="#122670"
    />
    <path
      d="M38.3856 29.2591C38.059 28.9325 37.511 28.9325 37.1844 29.2591L34.7821 31.6615C34.4555 31.9881 34.4555 32.536 34.7821 32.8626C35.1087 33.1893 35.6566 33.1893 35.9833 32.8626L38.3856 30.4603C38.7122 30.1337 38.7122 29.5858 38.3856 29.2591Z"
      fill="#122670"
    />
    <path
      d="M40.451 31.3348C40.1244 31.0082 39.5765 31.0082 39.2498 31.3348L36.8475 33.7372C36.5209 34.0638 36.5209 34.6117 36.8475 34.9383C37.1741 35.265 37.722 35.265 38.0487 34.9383L40.451 32.536C40.7776 32.2094 40.7776 31.6615 40.451 31.3348Z"
      fill="#122670"
    />
  </svg>
);

export default WhiteLogo;
