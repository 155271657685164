import React, { useEffect, useRef, useState } from "react";
import Gradient from "../assets/images/gradient.png";
import { Reveal } from "../utils/Reveal";

import "swiper/css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import KoreanTeamImage from "../assets/images/about-section1-1.jpg";
import VietnamTeamImage from "../assets/images/about-section1-2.jpg";
import WorldMap from "../assets/images/world-map.png";
import KoreaFlag from "../assets/korea-flag";
import KoreaFlag1 from "../assets/korea-flag-1";
import VietnamFlag from "../assets/vietnam-flag";
import VietnamFlag1 from "../assets/vietnam-flag-1";
import {
  TabAbout_OurStory1,
  TabAbout_OurStory2,
  TabAbout_OurStory3,
  TabAbout_PaperAboutUs,
} from "../mediaData";
import ArticleCard from "../utils/ArticleCard";
import FirstSection from "../utils/FullScreenSection";
import ThumbnailViewer from "../utils/ThumbnailViewer";

const AboutPage = () => {
  const [showAll, setShowAll] = useState(false);
  const [indexSlide1, setIndexSlide1] = React.useState(-1);
  const [indexSlide2, setIndexSlide2] = React.useState(-1);
  const [indexSlide3, setIndexSlide3] = React.useState(-1);

  const circleRefs = [useRef(null), useRef(null), useRef(null)];
  const [isCentered, setIsCentered] = useState([false, false, false]);
  const [visibleItemsCount, setVisibleItemsCount] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (768 <= window.innerWidth && window.innerWidth <= 1024) {
        setVisibleItemsCount(4);
      } else {
        setVisibleItemsCount(3);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Create observers for each circle
    const observers = circleRefs.map((circleRef, index) => {
      return new IntersectionObserver(
        ([entry]) => {
          if (entry.intersectionRatio > 0.5) { // Add a threshold to stabilize state updates
            setIsCentered((prevState) => {
              const newState = [false, false, false]; // Reset all circles to false
              newState[index] = true; // Set only the current circle to true
              return newState;
            });
          }
        },
        {
          root: null,
          threshold: 0.5, // Smaller threshold to trigger early when entering the viewport
          rootMargin: "0px 0px -45% 0px", // Activate when 1/4 bottom of the screen is crossed
        }
      );
    });

    // Observe each circle
    circleRefs.forEach((circleRef, index) => {
      if (circleRef.current) {
        observers[index].observe(circleRef.current);
      }
    });

    // Cleanup
    return () => {
      circleRefs.forEach((circleRef, index) => {
        if (circleRef.current) {
          observers[index].unobserve(circleRef.current);
        }
      });
    };
  }, []);

  return (
    <div className="w-full">
      {/* First Section */}
      <FirstSection>
        <Reveal>
          <div className="gap-5 flex flex-col p-[5vw]">
            <h1 className="text-2xl font-bold text-white sm:text-3xl md:text-5xl lg:text-6xl xl:text-7xl mb-2">
              <span className="text-blue-500">A</span>bout Us{" "}
            </h1>
          {/* English Description */}
          <p className="sm:text-sm md:text-2xl sm:leading-[24px] text-neutral-200 whitespace-pre-line">
            We are experts in AI, Cloud{"\n"}
            We are fast application developers
          </p>

          {/* Korean Description */}
          <p className="sm:text-sm md:text-2xl sm:leading-[24px] text-neutral-200 whitespace-pre-line">
            우리는 AI와 클라우드 분야 전문가이며{"\n"}
            신속하고 빠르게 어플리케이션을 개발해냅니다
          </p>
          </div>
        </Reveal>
      </FirstSection>

      {/* Our teams Section */}
      <section
        className="w-full  py-10  gap-[40px] flex flex-col"
        style={{
          backgroundImage: `url(${WorldMap})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* title */}
        <Reveal className="w-full flex flex-row items-left gap-1 h-fit justify-center">
          <div className="mt-3 h-3 min-w-3 rounded-[4px] bg-primary-700 text-wrap" />
          <div className="mt-3 size-3 rounded-[4px] bg-secondary" />
          <p className=" text-center text-2xl font-semibold text-neutral-600">
          Teams 
          </p>
        </Reveal>

        {/* Korean Team & Vietnam Team */}
        <div className="">
          <Reveal className="-mt-10 lg:mt-0 lg:-mb-20 relative lg:h-[400px] -mb-10 w-full">
            <div className=" flex lg:flex-row flex-col justify-between lg:h-[400px] w-full">
              {/* Phần tử bên trái: Hình ảnh */}
              <div className="flex h-[400px] p-5 pb-10  justify-center ">
                <div className="relative w-full">
                  <img
                    src={KoreanTeamImage}
                    alt="KoreanTeam"
                    className="object-cover h-full w-full rounded-3xl lg:w-[calc(50vw-60px)]"
                    style={{
                      // width: "calc(50vw - 120px)",
                      boxShadow: "2px 10px 24px 2px rgba(22, 22, 22, 0.1)",
                    }}
                  />
                  <div className="absolute lg:hidden -bottom-[180px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center p-5 rounded-full bg-white">
                    <KoreaFlag />
                  </div>
                </div>
              </div>
              {/* Phần tử bên phải: Văn bản */}
              <div
                className="flex lg:h-[400px] p-5 lg:p-0 lg:justify-start items-center lg:pl-5 lg:pr-10 py-10  justify-center lg:w-[calc(50vw-60px)]"
                // size="(max-width: 640px) 90vw,
                //         (max-width: 768px) calc(50vw - 60px),
                //         // (max-width: 1024px) calc(50vw - 120px),
                //         calc(50vw - 120px)"
                // style={{ width: "calc(50vw - 120px )" }}
              >
                <div className="my-5 flex flex-col gap-5 max-w-[470px] items-center">
                  <div className="flex flex-row w-fit gap-3 items-center">
                    <div className="h-5 w-5 rounded-[4px] bg-primary-700" />
                    <span className="inline-block text-48-700 font-bold text-[#333333]">
                      Team KR
                    </span>
                    <div className="h-5 w-5 rounded-[4px] bg-secondary" />
                  </div>

                  <span className="text-xs sm:text-[16px] leading-[20px] sm:leading-[24px] text-neutral-800 lg:text-left whitespace-pre-line">
                    Vision AI와 LLM을 비롯한 다양한 AI 프로젝트를 수행합니다. 
                    AI, 인프라, 클라우드, 쿠버네티스 분야의 전문가들입니다. {"\n"}
                    Proficient in infrastructure, cloud, Kubernetes, and AI
                  </span>

                </div>
              </div>
            </div>
            {/* Phần tử ở giữa: Icon SVG */}
            <div className="hidden md:hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:flex justify-center items-center p-5 rounded-full bg-white">
              <KoreaFlag1 />
            </div>
          </Reveal>

          <Reveal className=" relative lg:h-[400px] -mt-10 w-full -mb-20">
            <div className=" flex lg:flex-row flex-col-reverse justify-between lg:h-[400px] w-full">
              {/* Phần tử bên trái:   Văn bản*/}
              <div
                className="flex lg:h-[400px] p-5  lg:p-0 lg:justify-end  lg:items-center lg:pl-10 py-10  justify-center align-top lg:w-[calc(50vw-60px)]"
                // style={{ width: "calc(50vw - 120px )" }}

                // size="(max-width: 640px) 90vw,
                //         (max-width: 768px) calc(50vw - 60px),
                //         (max-width: 1024px) calc(50vw - 90px),
                //         calc(50vw - 120px)"
              >
                <div className="my-5  flex flex-col gap-5 max-w-[470px] items-center ">
                  <div className="flex flex-row w-fit gap-3 items-center">
                    <div className="h-5 w-5 rounded-[4px] bg-primary-700" />
                    <span className="inline-block text-48-700 font-bold text-[#333333]">
                      Team VN
                    </span>
                    <div className="h-5 w-5 rounded-[4px] bg-secondary" />
                  </div>

                  <span className="text-16-400-20 text-neutral-800 lg:text-left whitespace-pre-line">
                    호치민(HCMC)에 지사를 두고있습니다.
                    신속한 어플리케이션 개발과 디자인 전문가들입니다. {"\n"}
                    Proficient in application design and development,{"\n"}
                    rapidly transforming new technologies into applications.
                </span>
                </div>
              </div>

              {/* Phần tử bên phải:Hình ảnh */}
              <div className="flex h-[400px] p-5 pb-10  justify-center ">
                <div className="relative w-full">
                  <img
                    src={VietnamTeamImage}
                    className="object-cover h-full w-full rounded-3xl lg:w-[calc(50vw-60px)]"
                    style={{
                      // width: "calc(50vw - 120px)",
                      boxShadow: "2px 10px 24px 2px rgba(22, 22, 22, 0.1)",
                    }}
                  />
                  <div className="absolute lg:hidden -bottom-[180px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center p-5 rounded-full bg-white">
                    <VietnamFlag />
                  </div>
                </div>
              </div>
            </div>
            {/* Phần tử ở giữa: Icon SVG */}
            <div className="hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:flex justify-center items-center p-5 rounded-full bg-white">
              <VietnamFlag1 />
            </div>
          </Reveal>
        </div>
      </section>

      {/* Our Story Section */}
      <section
        className="w-full lg:px-[10vw] gap-[40px] py-10 flex flex-col justify-center items-center"
        style={{
          backgroundImage: `url(${Gradient})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* title */}
        <div className="flex flex-row items-center gap-1 h-fit justify-center">
        <div className="h-3 w-3 rounded-[4px] bg-primary-700" />
        <div className="h-3 w-3 rounded-[4px] bg-secondary" />
        <p className="text-center text-2xl font-semibold text-neutral-600">
          Our History
        </p>
      </div>
        <div className="p-5 flex flex-col justify-center gap-[60px] w-fit">
          {/* Image slide 1 */}
          <div className="lg:w-[90vw] w-[90vw] h-fit lg:pr-[160px] pr-[32px] lg:!min-pr-[168px] ">
            <div
              className={`border border-t-0 border-r-0 rounded-bl-[60px] border-b-[20px] border-l-[20px] lg:rounded-bl-[180px] lg:border-b-[60px] lg:border-l-[60px] transition-colors ${
                isCentered[0] ? "border-primary-700" : "border-[#DDE3F9]"
              }`}
            >
              <Reveal className="flex lg:flex-row flex-col lg:pl-10   lg:pb-10 p-5 pr-0 items-start align-middle  gap-5 lg:gap-10">
                <div
                  className={` rounded-full flex items-center bg-primary-700 justify-center `}
                >
                  <p
                    className={` w-[120px] text-24-700 text-white items-center transition-all duration-700 flex flex-col justify-center ${
                      isCentered[0]
                        ? "h-[150px] w-[150px]"
                        : "h-[120px] w-[120px] "
                    }`}
                  >
                    Nov <p /> 2017
                  </p>
                </div>

                <div className="gap-4 flex flex-col ">
                  <p
                    ref={circleRefs[0]}
                    className="text-neutral-800 text-18-600 leading-[22px]"
                  >
                    Dudaji Korea published{" "}
                  </p>

                  <div className="flex flex-wrap gap-3">
                    {TabAbout_OurStory1.map((item, index) => (
                      <Reveal>
                        <ThumbnailViewer
                          key={index}
                          src={item.src}
                          isVideo={item.isVideo}
                          onClick={() => setIndexSlide1(index)}
                        />
                      </Reveal>
                    ))}
                    {indexSlide1 !== -1 && (
                      <Lightbox
                        index={indexSlide1}
                        slides={TabAbout_OurStory1}
                        open={indexSlide1 >= 0}
                        close={() => setIndexSlide1(-1)}
                      />
                    )}
                  </div>
                </div>
              </Reveal>
            </div>
          </div>

          {/* Image slide 2 */}
          <div className="lg:w-[90vw] h-fit lg:-mt-[120px] -mt-[80px] ml-14 lg:pl-[120px]">
            <div
              className={`border lg:pt-10 pt-5 border-l-0 lg:rounded-r-full rounded-r-[60px]  border-b-[20px] border-r-[20px] border-t-[20px] lg:border-b-[60px] lg:border-r-[60px] lg:border-t-[60px] transition-colors duration-700 ${
                isCentered[1] ? "border-error-300" : "border-[#FBE1DF]"
              }`}
            >
              <Reveal className="flex lg:flex-row flex-col-reverse lg:items-center items-end lg:pr-10 lg:pb-10 pb-5 pl-10 pr-5  align-bottom justify-end  gap-5 lg:gap-10">
                <div className="gap-4 flex flex-col">
                  <p
                    ref={circleRefs[1]}
                    className="text-neutral-800 text-18-600 leading-[22px] text-right"
                  >
                    Dudaji Vietnam published{" "}
                  </p>

                  <div className="flex justify-end flex-wrap gap-3">
                    {TabAbout_OurStory2.map((item, index) => (
                      <Reveal>
                        <ThumbnailViewer
                          key={index}
                          src={item.src}
                          isVideo={item.isVideo}
                          onClick={() => setIndexSlide2(index)}
                        />
                      </Reveal>
                    ))}
                    {indexSlide2 !== -1 && (
                      <Lightbox
                        index={indexSlide2}
                        slides={TabAbout_OurStory2}
                        open={indexSlide2 >= 0}
                        close={() => setIndexSlide2(-1)}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`bg-error-400 rounded-full flex justify-center items-center align-middle`}
                >
                  <p
                    className={` w-[120px] text-24-700 text-white items-center transition-all duration-700 flex flex-col justify-center ${
                      isCentered[1]
                        ? "h-[150px] w-[150px]"
                        : "h-[120px] w-[120px] "
                    }`}
                  >
                   Apr <p /> 2022
                  </p>
                </div>
              </Reveal>
            </div>
          </div>

          {/* Image slide 3 */}
          <div className="md:w-[90vw] w-fit h-fit  lg:-mt-[120px] -mt-[80px] lg:pr-[160px] pr-14">
            <div
              className={`border border-b-0 border-r-0 lg:rounded-tl-[180px] lg:border-t-[60px] lg:border-l-[60px] rounded-tl-[60px] border-t-[20px] border-l-[20px]  lg:pt-10 pt-5  ${
                isCentered[2] ? "border-primary-700" : "border-[#DDE3F9] "
              } `}
            >
              <div className="flex lg:flex-row flex-col items-start lg:pl-10 pl-5 lg:pb-10 pb-5 align-middle gap-5 lg:gap-10">
                <div
                  className={`bg-primary-700 rounded-full flex justify-center items-center `}
                >
                  <p
                    className={` w-[120px] text-24-700 text-white items-center transition-all duration-700 flex flex-col justify-center ${
                      isCentered[2]
                        ? "h-[150px] w-[150px]"
                        : "h-[120px] w-[120px] "
                    }`}
                  >
                    Nov <p /> 2024
                  </p>
                </div>

                <div className="gap-4 flex flex-col">
                  <p
                    ref={circleRefs[2]}
                    className="text-neutral-800 text-18-600 leading-[22px]"
                  >
                    Dudaji Korea is still growing{" "}
                  </p>

                  <div className="flex flex-wrap gap-3">
                    {TabAbout_OurStory3.map((item, index) => (
                      <Reveal>
                        <ThumbnailViewer
                          key={index}
                          src={item.src}
                          isVideo={item.isVideo}
                          onClick={() => setIndexSlide3(index)}
                        />
                      </Reveal>
                    ))}
                    {indexSlide3 !== -1 && (
                      <Lightbox
                        index={indexSlide3}
                        slides={TabAbout_OurStory3}
                        open={indexSlide3 >= 0}
                        close={() => setIndexSlide3(-1)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* People talked about us Section */}
      <section className="w-full lg:p-[5vw] py-10 px-5 gap-[40px] flex flex-col items-center">
        {/* title */}
        <Reveal className=" flex flex-row items-left gap-1 h-fit justify-center">
          <div className="mt-3 h-3 min-w-3 rounded-[4px] bg-primary-700 text-wrap" />
          <div className="mt-3 size-3 rounded-[4px] bg-secondary" />
          <p className="mb-1 h-6 text-center text-2xl font-semibold text-neutral-600">
            People talked about us
          </p>
        </Reveal>
        <Reveal className="lg:gap-10 gap-5 flex flex-wrap justify-center lg:grid md:grid lg:grid-cols-3 md:grid-cols-2 ">
          {TabAbout_PaperAboutUs.slice(0, visibleItemsCount).map(
            (item, index) => (
              <Reveal>
                <ArticleCard key={index} data={item} />
              </Reveal>
            )
          )}

          {showAll &&
            TabAbout_PaperAboutUs.slice(visibleItemsCount).map(
              (item, index) => (
                <Reveal>
                  <ArticleCard key={index} data={item} />
                </Reveal>
              )
            )}
        </Reveal>
        <button
          className={`w-[150px] h-[44px] self-center justify-self-center rounded-xl border border-[#E6E6E6] p-3  transition-opacity pb-4 ${
            showAll ? "opacity-0 hidden" : "opacity-100"
          }`}
          onClick={() => setShowAll(true)}
        >
          <p className="text-16-600-20">Show more </p>
        </button>
      </section>
      <script src="https://unpkg.com/lucide@latest"></script>
      <script>lucide.createIcons();</script>
    </div>
  );
};

export default AboutPage;
