import React from 'react';
import BlueCard from "../utils/BlueCard";

const ProductCard = ({ logo: LogoComponent, title, since, description, onClick }) => {
    return (
      <BlueCard className="gap-5 flex flex-col justify-between w-full max-w-[500px] min-w-[310px] p-5 bg-bluecard">
        <div className="gap-5 flex flex-col">
          <div className="flex items-center gap-5">
            <LogoComponent />
            <div className="flex flex-col items-start text-start gap-3">
              <h2 className="text-white text-[24px] font-bold">{title}</h2>
              <div className="flex flex-row items-center gap-1">
                <p className="text-neutral-300 text-[14px] font-semibold">Since {since}</p>
              </div>
            </div>
          </div>
          <p className="text-white text-xs font-normal whitespace-pre-line">
            {description}
          </p>
        </div>
        <button
          onClick={onClick}
          className="h-11 w-[120px] mt-4 rounded-xl bg-error-400 text-white flex items-center justify-center"
        >
          Explore
        </button>
      </BlueCard>
    );
  };
  
  export default ProductCard;
