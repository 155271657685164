// src/components/Footer.js
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Gradient from "../assets/images/gradient.png";
import homeImage from "../assets/images/home.jpg";
import aboutImage from "../assets/images/about_img.png"
import { Reveal } from "../utils/Reveal";
import "swiper/css";
import ImageSlider from "../components/ImageSlider";
import { TabHome_Slide1, TabHome_Slide2 , TabHome_Slide3, TabHome_Slide4} from "../mediaData";
import BlueCard from "../utils/BlueCard";
import FirstSection from "../utils/FullScreenSection";
import ProductCard from "../components/ProductCard";
import CapLogo from "../assets/cap-logo";
import MiddoLogo from "../assets/middo-logo";
import NufiLogo from "../assets/nufi-logo";

const HomePage = () => {
  const [partnersCount, setPartnersCount] = useState(0);
  const [modelsCount, setModelsCount] = useState(0);

  const targetPartnersCount = 33;
  const targetModelsCount = 60;

  const navigate = useNavigate();
  
  const handleNavigation = (hash) => {
    navigate(`/product#${hash}`);
  };

  useEffect(() => {
    const countUp = (target, setter) => {
      let count = 0;
      const increment = () => {
        if (count < target) {
          count++;
          setter(count);
          setTimeout(increment, 10 + (target - count) * 2);
        }
      };
      increment();
    };

    countUp(targetPartnersCount, setPartnersCount);
    countUp(targetModelsCount, setModelsCount);


  }, []);
  return (
    <div className="w-full">
      {/* First Section */}
      <FirstSection className="">
        <Reveal className="gap-3 flex flex-col p-4 mb-5">
          <h1 className="text-2xl font-bold text-white sm:text-3xl md:text-5xl lg:text-7xl xl:text-8xl mb-2">
            {/* First line */}
            <span className="lg:inline-block whitespace-pre-line" >
              <span className="text-blue-500">A</span>I{" "}
              <span className="text-blue-500">B</span>eyond{" "}
              <span className="text-blue-500">C</span>omputing{"\n"}
            </span>
            <br className="hidden lg:block" />
            {/* Second line */}
            <span className="lg:inline-block">
              <span className="text-blue-500">D</span>udaji{" "}
              <span className="text-blue-500">E</span>mpowers{" "}
              <span className="text-blue-500">F</span>uture
            </span>
          </h1>
 
        </Reveal>

        <div className="flex flex-wrap justify-center md:grid-cols-2 md:gap-[60px] gap-10">
          {/* Stats */}
          <Reveal>
            <BlueCard className="flex justify-center w-[90vw]  h-[180px] md:w-[380px] md:h-[200px]">
              <div>
                <h2 className="text-[18px] leading-[22px] font-semibold text-white">
                  Our partners
                </h2>
                <p className=" h[98px] text-[72px] leading-[98px] mt-2 text-primary-50 font-bold  text-center">
                  {partnersCount}+
                </p>
              </div>
            </BlueCard>
          </Reveal>
          <Reveal>
            <BlueCard className="flex justify-center w-[90vw] h-[180px] md:w-[380px] md:h-[200px]">
              <div>
                <h2 className="text-[18px] leading-[22px] font-semibold text-white">
                  AI Project Done
                </h2>
                <p className="font-bold h[98px] text-[72px] leading-[98px] mt-2 text-primary-50 text-center">
                  {modelsCount}+
                </p>
              </div>
            </BlueCard>
          </Reveal>
        </div>
      </FirstSection>
        {/* Business Area Section */}
      <div
        className="w-full py-5 px-5 md:px-[5vw] gap-[10px] flex flex-col"
        style={{
          backgroundImage: `url(${Gradient})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Centered Header */}
        <div className="w-full flex flex-col items-center">
          <Reveal className="flex flex-row items-center gap-1 h-fit justify-center">
            <div className="h-3 w-3 rounded-[4px] bg-primary-700 hidden sm:block" /> {/* 모바일에서 숨김 */}
            <div className="h-3 w-3 rounded-[4px] bg-secondary hidden sm:block" /> {/* 모바일에서 숨김 */}
            <p className="text-lg font-semibold text-neutral-600 sm:text-xl md:text-2xl text-center">
              Business Area
            </p>
          </Reveal>
        </div>

        <div className="flex flex-col">
          {/* Showcase 1 */}
          <div className="flex flex-wrap lg:gap-[10px] gap-1 items-center justify-center">
            {/* Text */}
            <Reveal className="flex flex-col gap-1 flex-1 min-w-[200px] lg:min-w-[210px] self-center items-left order-1 lg:order-none">
              <div className="flex flex-row items-center gap-1 self-start">
                <div className="w-4 h-4 rounded-[4px] bg-primary-700" />
                <span className="text-3xl font-bold text-black leading-none sm:text-3xl md:text-[36px]">01</span>
                <div className="w-4 h-4 rounded-[4px] bg-secondary" />
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-3xl font-bold text-[#333333] sm:text-5xl md:text-6xl">
                  Vision AI
                </span>
                <span className="text-sm font-normal text-[#000000] sm:text-base md:text-lg">
                  We lead high-compatibility Vision AI projects designed for seamless integration across diverse environments, including NPUs.
                </span>
              </div>
            </Reveal>
            {/* Image Slider */}
            <ImageSlider slides={TabHome_Slide1} className="order-2 lg:order-none" instanceId="0" />
          </div>

          {/* Separator */}
          <hr className="w-full border-t border-gray-100 my-1" />

          {/* Showcase 2 */}
          <div className="flex flex-wrap lg:flex-row-reverse lg:gap-[10px] gap-1 items-center justify-center">
            {/* Text */}
            <Reveal className="flex flex-col gap-5 flex-1 min-w-[200px] lg:min-w-[210px] self-center items-left order-1 lg:order-none">
              <div className="flex flex-row items-center gap-2 self-start">
                <div className="w-4 h-4 rounded-[4px] bg-primary-700" />
                <span className="text-3xl font-bold text-black leading-none sm:text-4xl md:text-[48px]">02</span>
                <div className="w-4 h-4 rounded-[4px] bg-secondary" />
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-3xl font-bold text-[#333333] sm:text-5xl md:text-6xl">LLM</span>
                <span className="text-sm font-normal text-[#000000] sm:text-base md:text-lg">
                  Our research focuses on creating secure, customizable, and NPU-optimized LLM Services.
                </span>
              </div>
            </Reveal>
            {/* Image Slider */}
            <ImageSlider slides={TabHome_Slide2} className="order-2 lg:order-none" instanceId="1" />
          </div>

          {/* Separator */}
          <hr className="w-full border-t border-gray-100 my-1" />

          {/* Showcase 3 */}
          <div className="flex flex-wrap lg:gap-[10px] gap-1 items-center justify-center">
            {/* Text */}
            <Reveal className="flex flex-col gap-3 flex-1 min-w-[200px] lg:min-w-[210px] self-center items-left order-1 lg:order-none">
              <div className="flex flex-row items-center gap-2 self-start">
                <div className="w-4 h-4 rounded-[4px] bg-primary-700" />
                <span className="text-3xl font-bold text-black leading-none sm:text-4xl md:text-[48px]">03</span>
                <div className="w-4 h-4 rounded-[4px] bg-secondary" />
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-3xl font-bold text-[#333333] sm:text-5xl md:text-6xl">ROS</span>
                <span className="text-sm font-normal text-[#000000] sm:text-base md:text-lg">
                  We have experience in robotic control projects.
                </span>
              </div>
            </Reveal>
            {/* Image Slider */}
            <ImageSlider slides={TabHome_Slide3} className="order-2 lg:order-none" instanceId="2" />
          </div>

          {/* Separator */}
          <hr className="w-full border-t border-gray-100 my-1" />

          {/* Showcase 4 */}
          <div className="flex flex-wrap lg:flex-row-reverse lg:gap-[10px] gap-1 items-center justify-center">
            {/* Text */}
            <Reveal className="flex flex-col gap-5 flex-1 min-w-[200px] lg:min-w-[210px] self-center items-left order-1 lg:order-none">
              <div className="flex flex-row items-center gap-2 self-start">
                <div className="w-4 h-4 rounded-[4px] bg-primary-700" />
                <span className="text-3xl font-bold text-black leading-none sm:text-4xl md:text-[48px]">04</span>
                <div className="w-4 h-4 rounded-[4px] bg-secondary" />
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-3xl font-bold text-[#333333] sm:text-5xl md:text-6xl">MLOps</span>
                <span className="text-sm font-normal text-[#000000] sm:text-base md:text-lg">
                  Our team brings extensive experience in designing, deploying, and managing robust MLOps platforms across various applications.
                </span>
              </div>
            </Reveal>
            {/* Image Slider */}
            <ImageSlider slides={TabHome_Slide4} className="order-2 lg:order-none" instanceId="3" />
          </div>
        </div>

        {/* Products Section */}
        <div
          className="min-h-[30vh] w-full bg-cover bg-no-repeat bg-center flex flex-col items-center py-5 px-5"
        >
          {/* Centered Header */}
          <div className="w-full flex flex-col items-center mb-5">
            <Reveal className="flex flex-row items-center gap-1 h-fit justify-center">
              <div className="h-3 w-3 rounded-[4px] bg-primary-700" />
              <div className="h-3 w-3 rounded-[4px] bg-secondary" />
              <p className="text-lg font-semibold text-neutral-600 sm:text-xl md:text-2xl text-center">
                Products
              </p>
            </Reveal>
          </div>

          {/* Product Cards */}
          <div className="w-full lg:w-[80vw] grid grid-cols-1 lg:grid-cols-3 gap-10 justify-items-center">
            <ProductCard
              logo={NufiLogo}
              title="Nufi"
              since="2024"
              description={`한국의 AI 반도체(NPU) 호환성을 확보한 플랫폼으로 NPU를 좀더 간편하게 사용할 수 있게 해줍니다.\nAI CHIP(NPU) based AI Inference Platform Provides all tools to develop AI with NPU`}
              onClick={() => handleNavigation('nufi')}
            />
            <ProductCard
              logo={MiddoLogo}
              title="Middo"
              since="2023"
              description={`실시간 E.S.L 번역을 지원하는 커뮤니케이션 앱으로 Extension, Bot을 통한 LLM 또한 지원합니다.\nGlobal communication application with real-time ESL translation and LLM support`}
              onClick={() => handleNavigation('middo')}
            />
            <ProductCard
              logo={CapLogo}
              title="CAP"
              since="2020"
              description={`쿠버네티스 기반 End to End MLOps 솔루션으로 AI 연구와 서비스 개발에 몰두할수 있게 해줍니다.\nCloud Based AI Platform with End to End MLOps, working on Kubernetes`}
              onClick={() => handleNavigation('cap')}
            />
          </div>
        </div>
 

        {/* Separator Line with Reduced Margin */}
          <hr className="w-full border-t border-gray-100 my-0.5" />

        {/* About Section */}
        <div
          className="min-h-[30vh] w-full bg-cover bg-no-repeat bg-center flex flex-col items-center py-5 px-5"
        >
          {/* Centered Header */}
          <div className="w-full flex flex-col items-center mb-5">
            <Reveal className="flex flex-row items-center gap-1 h-fit justify-center">
              <div className="h-3 w-3 rounded-[4px] bg-primary-700" />
              <div className="h-3 w-3 rounded-[4px] bg-secondary" />
              <p className="text-lg font-semibold text-neutral-600 sm:text-xl md:text-2xl text-center">
                About
              </p>
            </Reveal>
          </div>

          {/* Go to About Us */}
          <div className="w-full lg:w-[100vw] flex flex-col items-center gap-10 py-5">
            <div className="flex flex-col items-center text-center gap-5 max-w-lg">
              <p className="text-lg text-gray-600">
                We are experts in AI and Cloud, fast application Developers.
              </p>
              <p className="text-lg text-gray-600 lg:whitespace-nowrap whitespace-normal">
                두다지는 AI와 클라우드 분야 전문가이며, 신속하고 빠르게 어플리케이션을 개발해냅니다.
              </p>
              {/* Image Section */}
              <div className="w-full flex justify-center">
                <img 
                  src={aboutImage} 
                  alt="About Us" 
                  className="w-full max-w-[300px] sm:max-w-[400px] lg:max-w-[1080px] h-auto rounded-lg shadow-lg" 
                />
              </div>
              {/* Simple Button */}
              <div className="mt-6">
                <button
                  onClick={() => {
                    navigate('/about');
                    window.scrollTo(0, 0); // Ensures the page scrolls to the top
                  }}
                  className="px-6 py-3 bg-primary-700 text-white rounded-lg"
                >
                  Learn More About Us
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr className="w-full border-t border-gray-100 my-1" />

           {/* Contact Section */}
          <div
            className="min-h-[30vh] w-full bg-cover bg-no-repeat bg-center flex flex-col items-center py-5 px-5"
          >
          {/* Centered Header */}
          <div className="w-full flex flex-col items-center mb-5">
            <Reveal className="flex flex-row items-center gap-1 h-fit justify-center">
              <div className="h-3 w-3 rounded-[4px] bg-primary-700" />
              <div className="h-3 w-3 rounded-[4px] bg-secondary" />
              <p className="text-lg font-semibold text-neutral-600 sm:text-xl md:text-2xl text-center">
                Contact
              </p>
            </Reveal>
          </div>

          {/* Go to Contact Us */}
          {/* Simple Button */}
          <div className="min-h-[10vh] w-full bg-primary-700 flex flex-col items-center justify-center py-10 px-5">
            {/* Contact Section Header */}
            <div className="text-center mb-4">
            <h2 className="text-3xl font-bold text-white">문의 및 상담</h2>
              <p className="text-white text-lg mt-2">
                Business Inquiry 
              </p>
            </div>
            {/* Contact Button */}
            <button
              onClick={() => {
                navigate('/contact');
                window.scrollTo(0, 0); // Scrolls to the top of the Contact page
              }}
              className="mt-8 px-6 py-3 bg-black text-white rounded-lg text-lg font-semibold hover:bg-gray-800 transition"
            >
               상담 요청하기 / Request Consultation 
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
