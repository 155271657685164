const NufiLogo = ({ className = "!h-24 !w-24 " }) => (
  <div className="relative">
    <div className={`${className}`}>
      <div className="absolute h-24 !w-24 -top-[14px] -left-4">
        <svg
          width="132"
          height="132"
          viewBox="0 0 132 132"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_42_2408)">
            <rect x="16" y="14" width="96" height="96" rx="20" fill="white" />
            <mask
              id="mask0_42_2408"
              // style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="32"
              y="48"
              width="65"
              height="28"
            >
              <path
                d="M96.0055 48.2923H32.2227V75.7077H96.0055V48.2923Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_42_2408)">
              <path
                d="M64.9661 67.3662V75.8851C64.9015 75.8932 64.845 75.8932 64.7843 75.8932C62.4148 75.8932 60.0934 74.9457 58.3618 73.1598L43.9056 58.2103L42.8519 57.1257L42.6138 56.8717V48.3527C43.5463 48.3287 44.4829 48.4576 45.3669 48.7279C46.7961 49.1592 48.1201 49.9453 49.214 51.0783L50.1466 52.0418L63.7025 66.06L64.7238 67.1203L64.9619 67.3662H64.9661Z"
                fill="#3C4C8A"
              />
              <path
                d="M40.9988 48.3527V75.0467C40.9988 75.5102 40.6194 75.8893 40.1551 75.8893H33.0664C32.6021 75.8893 32.2227 75.5102 32.2227 75.0467V57.5411C32.2227 54.0898 34.0675 50.8324 37.1638 49.2921C38.3991 48.6873 39.707 48.3771 40.9988 48.3527Z"
                fill="#293069"
              />
              <path
                d="M96.066 49.1349V56.2145C96.066 56.6781 95.6866 57.0571 95.2225 57.0571H76.9717V48.2923H95.2225C95.6866 48.2923 96.066 48.6714 96.066 49.1349Z"
                fill="#3C4C8A"
              />
              <path
                d="M75.3569 48.2923V66.9065C75.3569 69.64 74.1618 72.2769 72.006 73.9498C70.3549 75.2319 68.4538 75.8529 66.5806 75.8851V57.2185C66.5806 52.34 70.4962 48.381 75.3569 48.2923Z"
                fill="#293069"
              />
              <path
                d="M90.0714 62.9151H82.9624C82.4966 62.9151 82.1187 63.2925 82.1187 63.758V70.8577C82.1187 71.323 82.4966 71.7003 82.9624 71.7003H90.0714C90.5374 71.7003 90.9151 71.323 90.9151 70.8577V63.758C90.9151 63.2925 90.5374 62.9151 90.0714 62.9151Z"
                fill="#E89A96"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_42_2408"
              x="0"
              y="0"
              width="132"
              height="132"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="2"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow_42_2408"
              />
              <feOffset dx="2" dy="4" />
              <feGaussianBlur stdDeviation="8" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0862745 0 0 0 0 0.0862745 0 0 0 0 0.0862745 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_42_2408"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_42_2408"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  </div>
);
export default NufiLogo;
