import { PlayIcon } from "lucide-react";
import React from "react";

const ThumbnailViewer = ({
  src,
  isVideo = false,
  onClick,
  size = "w-[90px] h-[60px] rounded-[12px]",
  hoverAble = false,
}) => {
  return (
    <div
      className={`${size} flex items-center justify-center overflow-hidden cursor-pointer bg-gray-200 ${
        hoverAble &&
        "transition-transform transform hover:scale-105 duration-300"
      } `}
      onClick={onClick}
    >
      {isVideo && src.includes("youtube.com") ? ( // Check if src is a YouTube link
        <div className="relative flex items-center justify-center">
          <img
            src={src} // Display YouTube thumbnail as an image
            alt="Video Thumbnail"
            className="w-full h-full object-cover"
          />
          <div
            className="absolute flex justify-center items-center text-white text-3xl size-[68px] rounded-full bg-[#00000066]"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <PlayIcon color="white" fill="white" size={52} />
          </div>
        </div>
      ) : isVideo ? ( // Handle other video types, if any, as <video> elements
        <div className="relative flex items-center justify-center">
          <video
            src={src}
            className="w-full h-full object-cover"
            controls={false}
            muted
          />
          <div
            className="absolute flex justify-center items-center text-white text-3xl size-[68px] rounded-full bg-[#00000066]"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <PlayIcon color="white" fill="white" size={52} />
          </div>
        </div>
      ) : (
        <img src={src} alt="Thumbnail" className="w-full h-full object-contain bg-gray-900" />
      )}
    </div>
  );
};

export default ThumbnailViewer;
