// ImageSlide.js
import React from "react";
import { Reveal } from "./Reveal";

function FadeOverlay({ isVisible }) {
  return (
    <div
      className={` absolute inset-0  transform translate-y-[0%] -translate-x-[10%]  bg-white/80 rounded-[24px] transition-opacity items-center flex duration-300 ease-in-out ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
      style={{
        width: "100%",
        height: "100%",
        aspectRatio: "1 / 1",
      }}
    ></div>
  );
}

function ImageSlide({ src, alt, isActive, className = "", style = {} }) {
  return (
    <div>
      <Reveal className="h-full w-full items-center  inline-block rounded-[24px]">
        <FadeOverlay isVisible={!isActive} />
        <img
          src={src}
          alt={alt}
          style={{
            width: "90%",
            height: "90%",
            objectFit: "contain",
            aspectRatio: "1 / 1",
            ...style,
          }}
          className={`rounded-[24px]  object-cover ${className}`}
        />
      </Reveal>
    </div>
  );
}

export default ImageSlide;
