import React, { useRef } from "react";
import { Reveal } from "../utils/Reveal";
import "swiper/css";
import FirstSection from "../utils/FullScreenSection";
import "yet-another-react-lightbox/styles.css";
import { Send } from "lucide-react";
const ContactPage = () => {
  const formRef = useRef(null);

  const handleFormSubmit = (event) => {
    event.preventDefault(); // 기본 제출 방지
    alert("Your information will be sent to contact@dudaji.com.");
    // 폼을 초기화
    if (formRef.current) {
      formRef.current.reset();
    }
  };
  return (
    <div className="w-full">
      <FirstSection height="40vh">
        <Reveal>
          <div className="gap-5 flex flex-col p-16">
            <h1 className="text-4xl md:text-5xl  font-bold text-white lg:text-6xl">
              <span className="text-blue-500">C</span>ontact Us{" "}
            </h1>
          </div>
        </Reveal>
      </FirstSection>{" "}
      <div
        id="contact"
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-duration="800"
        className="lg:p-[5vw] px-5 py-10 lg:pt-12 md:pt-[5vw] flex lg:flex-row flex-col gap-5 lg:gap-[5vw]"
      >
        <div class="flex-1 flex flex-col gap-8 ">
          <h3 class="text-3xl font-semibold text-[#122670]">
            Dudaji <span class="text-[#FF9792]">Office</span>
          </h3>
          <div className="flex flex-col gap-5">
            {/* Address Section */}
            <div className="flex flex-col items-start gap-3">
              <p className="text-16-400-20 text-neutral-800">Address.</p>
              <span className="text-16-600-20 text-[#333]">
                6F DUDAJI, 36 Nambusunhwan-ro 218-gil, Gwanak-gu, Seoul
              </span>
            </div>

            {/* Korean Address Section */}
            <div className="flex flex-col items-start gap-3">
              <p className="text-16-400-20 text-neutral-800">오시는 길</p>
              <span className="text-16-600-20 text-[#333]">
                서울특별시 관악구 남부순환로 218길 36(문성빌딩) 6층 두다지
              </span>
            </div>

            {/* Email Section */}
            <div className="flex flex-col items-start gap-3">
              <p className="text-16-400-20 text-neutral-800">Email.</p>
              <span className="text-16-600-20 text-[#333]">contact@dudaji.com</span>
            </div>
          </div>

          <div class=" overflow-hidden rounded-xl lg:h-full h-[90vw] lg:max-h-full max-h-[500px]">
            <iframe
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ52mBYImffDUR7QTGzYgBERI&key=AIzaSyDBaYwVncIk9BQiBr_oxBdhzpJAihITv6U"
              // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.88679065653!2d127.0321426883197!3d37.46339480003855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca12a67476b9d%3A0x97d8fbe4061a0530!2s16%20Maeheon-ro%2C%20Seocho%20District%2C%20Seoul%2C%20South%20Korea!5e0!3m2!1sen!2sus!4v1730954375275!5m2!1sen!2sus"
              className="border-none h-full w-full"
              // style="border: 0; width: 100%; height: 100%"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div class="flex flex-col flex-1 items-stretch"></div>
        </div>
        <form
          ref={formRef} // formRef를 form 태그에 연결
          action="https://formsubmit.co/contact@dudaji.com"
          method="POST"
          id="contactForm"
          onSubmit={handleFormSubmit}
          className="flex-1 p-5 bg-[#F8FAFC] rounded-xl pb-10"
          autoComplete="on"
        >
          <div class="flex items-stretch gap-2">
            <div class="w-1 bg-[#122670]"></div>
            <p class="text-[#122670] font-medium whitespace-pre-line">
              Share a bit about yourself and let's connect.
            </p>
          </div>
          <div class="flex md:flex-row flex-col gap-5 mt-5">
            <div class="flex-1">
              <label class="block mb-2" for="client-name">
                Your name
              </label>
              <input
                required
                type="text"
                name="client-name"
                id="client-name"
                class="py-2 px-3 border border-[#f2f2f2] rounded-xl w-full"
                placeholder="Enter your name"
              />
            </div>
            <div class="flex-1">
              <label class="block mb-2" for="client-phone">
                Your phone number
              </label>
              <input
                required
                type="text"
                inputmode="tel"
                name="client-phone"
                id="client-phone"
                class="py-2 px-3 border border-[#f2f2f2] rounded-xl w-full"
                placeholder="Enter your phone number"
              />
            </div>
          </div>
          <div class="flex md:flex-row flex-col gap-5 mt-5">
            <div class="flex-1">
              <label class="block mb-2" for="client-mail">
                Your email
              </label>
              <input
                required
                type="text"
                name="client-mail"
                inputmode="email"
                id="client-mail"
                class="py-2 px-3 border border-[#f2f2f2] rounded-xl w-full"
                placeholder="Enter your email"
              />
            </div>
            <div class="flex-1">
              <label class="block mb-2" for="company-name">
                Your company name
              </label>
              <input
                required
                type="text"
                name="company-name"
                id="company-name"
                class="py-2 px-3 border border-[#f2f2f2] rounded-xl w-full"
                placeholder="Enter your company name"
              />
            </div>
          </div>
          <div class="mt-5">
            <label class="block mb-2" for="subject">
              Subject
            </label>
            <input
              required
              type="text"
              name="subject"
              id="subject"
              class="py-2 px-3 border border-[#f2f2f2] rounded-xl w-full"
              placeholder="Enter subject here"
            />
          </div>
          <div class="mt-5">
            <label class="block mb-2" for="message">
              Message
            </label>
            <textarea
              required
              name="message"
              id="message"
              class="w-full min-h-40 py-2 px-3 border border-[#f2f2f2] rounded-xl"
              placeholder="Enter your message here"
            ></textarea>
          </div>
          <div class="w-full flex justify-center mt-5">
            <button
              type="submit"
              class="md:w-fit formbtn group md:hover:bg-[#FF9792] transition-colors duration-500 ease-in-out flex justify-center items-center gap-2 px-7 h-12 rounded-xl bg-[#122670] w-full active:!bg-[#031532] text-white font-semibold relative min-h-14"
            >
              <span class=" relative">Send information</span>

              <Send size={20} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
