import React, { useEffect, useState } from "react";
import axios from "axios";
import DefaultPaperPreview from "../assets/images/default-paper-preview.jpg";
const getDomain = (url) => {
  try {
    return new URL(url).hostname;
  } catch (error) {
    console.error("Invalid URL:", url);
    return url;
  }
};
const ArticleCard = ({ data }) => {
  return (
    <a
      href={data.url}
      target="blank"
      className="p-3 gap-3 border rounded-3xl border-neutral-50 w-[90vw] max-w-[500px] lg:w-auto md:w-auto h-fit flex flex-col"
    >
      <img
        src={data.imgUrl || DefaultPaperPreview}
        alt="Thumbnail"
        className="object-cover"
        style={{
          width: "100%",
          height: "210px",
          borderRadius: "20px 20px 4px 4px",
        }}
      />
      <div>
        <p
          className="text-18-600 text-ellipsis overflow-hidden whitespace-nowrap"
          style={{ maxWidth: "100%" }}
        >
          {data.title}
        </p>
        <p className="text-16-400-20">{getDomain(data.url)}</p>
      </div>
    </a>
  );
};

export default ArticleCard;
