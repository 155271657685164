import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Swiper, SwiperSlide } from "swiper/react";

import { useState } from "react";
import "swiper/css";
import ImageSlide from "../utils/ImageSlide";
import { Reveal } from "../utils/Reveal";

const ImageSlider = ({ slides, className = "", instanceId = "0" }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const max_index = slides.length - 1;
  return (
    <div
      className={`relative w-full max-w-[750px] md:max-w-[640px] lg:max-w-[750px] mx-auto ${className}`}
    >
<Swiper
  effect={"coverflow"}
  grabCursor={true}
  centeredSlides={false}
  slidesPerView={1}
  className="w-[330px] md:w-[640px] lg:w-[750px]"
  coverflowEffect={{
    rotate: 0,
    stretch: 20,
    depth: 100, // Adjust depth for tighter spacing,
    modifier: 1,
    slideShadows: false,
  }}
  breakpoints={{
    768: {
      coverflowEffect: {
        stretch: 40,
      },
    },
    1024: {
      coverflowEffect: {
        stretch: 50,
      },
    },
  }}
  loop={true}  // Enable continuous looping here
  modules={[EffectCoverflow, Pagination, Navigation]}
  navigation={{
    nextEl: `.swiper-button-next-${instanceId}`,
    prevEl: `.swiper-button-prev-${instanceId}`,
  }}
  onSlideChange={(swiper) => {
    setActiveIndex(swiper.realIndex);
  }}
  speed={900}
>
  {slides.map((src, index) => (
    <Reveal key={index}>
      <SwiperSlide>
        <ImageSlide
          src={src}
          isActive={
            activeIndex === index ||
            index === (activeIndex - 1 + slides.length) % slides.length
          }
        />
      </SwiperSlide>
    </Reveal>
  ))}
</Swiper>

      <div
        className={`swiper-button-prev-${instanceId} absolute size-12 md:left-3 lg:left-3  left-2 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full cursor-pointer flex items-center justify-center z-10 ${
          activeIndex === 0 ? "opacity-20 cursor-default" : "opacity-100"
        }`}
      >
        <ChevronLeft size={24} />
      </div>
      <div
        className={`swiper-button-next-${instanceId} absolute size-12 right-2 md:right-0 lg:right-3 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full cursor-pointer flex items-center justify-center z-10 ${
          activeIndex === max_index
            ? "opacity-20 cursor-default"
            : "opacity-100"
        }`}
      >
        <ChevronRight size={24} />
      </div>
    </div>
  );
};

export default ImageSlider;
